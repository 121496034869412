import {filter} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

// tslint:disable-next-line:ban-types
declare let gtag: Function;


/**
 * https://blog.mestwin.net/add-google-analytics-to-angular-application-in-3-easy-steps/
 * https://www.dottedsquirrel.com/google-analytics-angular/
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  lastYear: string;

  // images = [
  //   {path: 'assets/slide1.jpg'},
  //   {path: 'assets/slide2.jpg'},
  //   {path: 'assets/slide3.jpg'},
  // ];
  //
  constructor(protected router: Router) {
    this.lastYear = new Date().getFullYear().toString();
  }

  ngOnInit(): void {
    this.setUpAnalytics();
  }

  isActive(name: string): boolean {
    return this.router.isActive(name, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
  }

  setUpAnalytics(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-52107886-1',
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }
}
