<div class="content_item">
  <h1>Oblasti služeb</h1>
  <h3>Oblast kontaktních center a IP telefonie</h3>
  <div class="icon-left">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Mé služby jsou zaměřeny na oblast kontaktních center a IP telefonie (Unified Communication, IPT) na platformách
    společností AVAYA, CISCO a Microsoft. Pro všechny uvedené platformy poskytuji konzultace, které mohou začínat pomocí
    s výběrem technologie, či přípravou zadání pro výběr technologií, přes pomoc od prvopočátečního návrhu, skrze pomoc
    v implementačním období až po efektivní pomoc při řešení provozních problémů.</p>
  <p>Mohu pomoci při jednáních s dodavateli řešení, směrovat projekt dle vašich potřeb a pomáhat s jeho úspěšným
    dokončením. Na straně dodavatele mohu být aktivním členem teamu či team leaderem při návrhu řešení, dodávce i
    podpoře řešeních v oblastí IP telefonních systémů, IP kontaktních center či videokonferenčních rešení.</p>
  <p>V portfoliu poskytovaných služeb mám modifikaci klientských aplikací kontaktních center, vytváření zákaznických
    reportů v systémech výrobců či na úrovni vlastního řešení postaveného na webovém rozhraní. Mohu významně pomoci s
    komunikací směrem k vedením kontaktního centra či překladem jejich požadavků do technické řeči.</p>
  <h3>Administrace systémů</h3>
  <div class="icon-right">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Pro menší společnosti jsem schopen zajistit za velmi výhodných podmínek vybudování i podporu serverového řešení
    postaveného na produktech společnosti Microsoft a Google. Pomohu od prvotního nastavení až po operativní řízení
    systému na bázi pravidelných kontrol potřebných úprav systému.</p>
  <p>Pomohu s volbou optimálního řešení při implementaci nového systému ve společnosti, s návrhem komunikační
    platformy. </p>
  <h3>Programování</h3>
  <div class="icon-left">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Doplňkovou činností je programování účelových nástrojů pro potřeby řešených projektů. Řeším i menší webové
    prezentace či úpravy stávajících systémů. U projektů vždy dbám na uživatelskou přívětivost, jednoduchost a nastavení
    funkcionality pouze na zákazníkem požadovanou, tak aby výsledný produkt plně vyhovoval potřebám, pro které byl
    stvořen.</p>
  <h3>AZURE, DevOps</h3>
  <div class="icon-right">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Díky novým zkušenostem s projekty, na kterých aktuálně pracuji, jsem mohl koncem roku 2017 rozšířit své služby o
    podporu projektů AZURE a Visual Studio Team Services (VSTS). V projektech jsem realizoval části týkající se operací
    řazených pod pojem DevOps. Implementoval jsem CI/CD projekty vázané na změny v kódu a přímo implementované v
    několika krocích až do zákaznického řešení. To vše ve spojení služeb VSTS a AZURE.</p>
  <p>V takovýchto projektech mohu pomoci se správným nastavením CI/CD pipeline, s nastavením zabezpečení, popsáním
    potřebných kroků pro úspěšné doručení verze až na do provozního systému. Samozřejmostí je i pomoc se získáním code
    signing certifikátu a jeho správná implementace v rámci celého procesu.</p>
  <h1>Jak mohu pomoci</h1>
  <h3>Jak pomohu zadavatelům</h3>
  <div class="icon-left">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Při tvorbě zadání pro výběr technologie IPT či kontaktního centra pomohu formulovat otázky, na které je třeba si
    odpovědět před a v průběhu přípravy zadání. Poradím jak posbírat potřebná data či zda je opravdu potřeba řešit body
    navržené výrobcem. Identifikuji zda vás výrobce netlačí do řešení hlavně pro svůj obchodní úspěch. Pomohu s vlastní
    textací zadání.</p>
  <p>Najdu technicky nejvhodnější řešení a řešení nejvíce odpovídající zadaní. Pomohu s hledáním technických hranic v
    nabídkách.</p>
  <p>Při implementaci pomohu s komunikací vůči dodavatelům a pomohu sledovat, zda dodavatel plní technické zadání tak
    jak byla dohodnuta. Mohu navrhovat změny vedoucí k úspěšnému dokončení projektu a zlepšení celého řešení.</p>
  <p>V době po implementaci mohu pomoci ve složitějších případech při řešení chyb systémů, mohu pomoci s hledáním
    dočasných řešení (workaround), mohu pomoci s kolektovaní dat pro technickou podporu a s technickou argumentací vůči
    dodavateli technické podpory řešení.</p>
  <h3>Jak pomohu dodavatelům</h3>
  <div class="icon-right">
    <img alt="Služby" src="assets/icons/news.png"/>
  </div>
  <p>Z poptávky navrhnu řešení odpovídající zadání na některé ze mne známých technologií. Pomohu s napsáním technických
    částí nabídky a jejich odůvodnění. Pomohu s obhajobou technických částí u zákazníka.</p>
  <p>Pomohu s implementací řešení jako architekt, implementátor či programátor v oblastech, které ovládám. Současně jsem
    schopen pomoci s komunikací směrem k zákazníkovi na projektových schůzkách.</p>
  <p>Po implementaci pomohu s řešením možných problémů a servisních aktivit.</p>
</div>
