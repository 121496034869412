<div class="main">
  <div class="site_content">

    <div class="header">
      <div class="site_heading">
        <h1>pokornyIt.cz</h1>
        <h2>Správně se říká "Nevím jak to udělat" a nikoliv "nejde to".</h2>
      </div>
      <div class="menubar">
        <ul class="menu">
          <li [class.current]="isActive('home')"><a routerLink="home">Domů</a></li>
          <li [class.current]="isActive('services')"><a routerLink="services">Služby</a></li>
          <li [class.current]="isActive('knowledge')"><a routerLink="knowledge">Znalosti</a></li>
          <li [class.current]="isActive('about')"><a routerLink="about">O mne</a></li>
          <li [class.current]="isActive('projects')"><a routerLink="projects">Projekty</a></li>
        </ul>
      </div>
    </div>

    <div class="content">
      <div class="banner_image">
                <app-slide-show></app-slide-show>

<!--        <carousel [images]="images"-->
<!--                  [objectFit]="'cover'"-->
<!--                  [cellWidth]="'100%'"-->
<!--                  [cellsToShow]="1"-->
<!--                  [autoplayInterval]="5000"-->
<!--                  [margin]="0"-->
<!--                  [arrowsOutside]="false"-->
<!--                  [arrows]="false"-->
<!--                  [autoplay]="true"-->
<!--                  [dots]="false"-->
<!--                  [loop]="true">-->
<!--        </carousel>-->
      </div>
      <!--   Content   -->
      <div class="content_item">
        <router-outlet></router-outlet>
      </div>
    </div>


    <div class="sidebar_container">
      <div class="sidebar">
        <div class="sidebar_item">
          <h4>Kontakt</h4>
          <p>
            Telefon: +420 (šest)02 262 017<br/>
            Email: zdenek (at) pokornyIt.cz
          </p>
        </div>
        <!--close sidebar_item-->
      </div>
      <!--close sidebar-->
      <div class="sidebar">
        <div class="sidebar_item">
          <h4>Povinné údaje</h4>
          <p>
            Zdeněk Pokorný<br/>
            Slepá 646, Vrané nad Vltavou, 252 45<br/>
            IČ: 16075561<br/>
            DIČ: CZ6802170892<br/>
            Fyzická osoba zapsaná v Živnostenském rejstříku od 27.2.1992<br/>
            D-U-N-S: 82-180-3355</p>
        </div>
        <!--close sidebar_item-->
        <div class="sidebar_item">
          <h4>Web hosting</h4>
          <p>Služby provozu tohoto serveru zajištěny u společnosti Web4U.</p>
          <a href="https://www.web4u.cz/rid/35725" style="width: 88px; height: 31px; border: none;" target="_blank">
            <img alt="DOMÉNY - WEBHOSTING - SERVERHOSTING"
                 src="https://www.web4u.cz/z_graphics/2012-web4u-promo/icons/ikona_basic_WHITE.gif"/></a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://letsencrypt.org/" style="width: 88px; height: 31px; border: none;" target="_blank">
            <img alt="Let’s Encrypt" height="55" src="https://letsencrypt.org/images/le-logo-standard.svg"/></a>
        </div>
        <!--close sidebar_item-->
        <!--close sidebar-->
      </div>
      <!--close sidebar-->
    </div>
  </div>

  <!-- footer --->
  <div class="footer">
    <p>
      <a routerLink="home">Domů</a> | <a routerLink="services">Služby</a>
      |
      <a routerLink="knowledge">Znalosti</a> |
      <a routerLink="about">O mne</a> | <a routerLink="projects">Projekty</a>
    </p>
    <p>
      2013 - {{lastYear}} &copy; Zdeněk Pokorný<br/>
      Na základě web desing <a href="http://www.araynordesign.co.uk">ARaynorDesign</a>
    </p>
  </div>

</div>
