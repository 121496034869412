<div class="content_item">
  <h1>Technologie</h1>
  <h3>AVAYA</h3>
  <div class="icon-right-knowledge"><img alt="Znalosti" src="assets/icons/work.png"/></div>
  <p>V oblasti technologií AVAYA jsem certifikovaným supportním inženýrem pro kontaktní centra, včetně multimediálních,
    pro Communication Manager a pro řešení Radvision. </p>
  <p>Mojí nejčastější prací v oblasti systému AVAYA je integrace AVAYA AURA Contact Center s externí databází/systémem
    či řešení reportingu vázaného s detailnějšími informacemi posbíranými v průběhu zpracování hovoru.</p>
  <h3>CISCO Systems</h3>
  <div class="icon-left-knowledge"><img alt="Znalosti" src="assets/icons/work.png"/></div>
  <p>Pro produkty CISCO systém nemám v současné době žádnou platnou certifikaci, ale od roku 1999 jsem aktivně pracoval
    s řešeními společnosti CISCO v oblasti kontaktních center a IP telefonie. Osobně jsem implementoval a dlouhodobě
    podporoval řešení u společností jako je CETELEM, VZP, DHL, CS a.s, CSA Services, Olymus CS, UAMK či u společnosti
    HVB Bank. Každé z řešení bylo unikátní a plně přizpůsobené požadavkům zákazníka. Všechna tato řešení jsem dodával
    pod hlavičkou společnosti Hewlett-Packard, s.r.o.</p>
  <p>Pro společnost CETELEM do dnešního dne podporuji systém pro zpracování email zpráv – CISCO E-mail Interaction
    Manager a to včetně unikátního řešení směrování zpráv dle slovníků, které si mohou zpravovat uživatelé dle svých
    potřeb.</p>
  <p>Pro společnost CSA Services jsem v roce 2007 vybudoval multimediální kontaktní centrum, které jsem i podporoval až
    do roku 2012. V tomto řešení byl unikátní reporting naprogramovaný přesně dle požadavků zadavatele. Systém
    reportingu obsahoval řadu vysoce specializovaných reportů a stal se nedílnou součástí každodenní práce supervizorů a
    team leaderů.</p>
  <h3>Microsoft</h3>
  <div class="icon-right-knowledge"><img alt="Znalosty" src="assets/icons/work.png"/></div>
  <p>Pro produkty společnosti Microsoft jsem certifikovaným implementátorem systému OCS 2007/2007 R2. Prováděl jsem
    implementaci a integraci systému Lync 2010 a Lync 2013 s hlasovými systémy společnosti CISCO Systems a AVAYA. V
    řešeních sytému Lync 2010/2013 jsem nastavoval propojení na jiné technologie, ale i řešil možnosti koexistence s již
    vybudovanými systémy IP telefonie.</p>
  <p>Pro projekty kontaktních center jsem řešil i implementaci a správu Microsoft Active Domain. Ve všech projektech
    bylo vždy snahou zajistit co nejsnazší potřebnou správu systému a jeho maximální stabilitu.</p>
  <h1>Programování</h1>
  <div class="icon-left-knowledge"><img alt="Znalosti" src="assets/icons/work.png"/></div>
  <p>Programuji pro systém Windows v jazycích C# NETFramework 4 a vyšší, C/C++ managed i native. Pro databázové systémy
    užívám MS SQL a ANSI SQL, včetně uložených procedur a spouští. Pro operační systémy typu Linux programuji v jazyce
    JAVA v kombinaci s řešením Apache Tomcat. Krom těchto jazyků ovládám Perl, PHP, Javascript, Basic, Pascal, Fortran a
    Cobol. Učení se dalších jazyků je pouze otázkou jejich potřebnosti pro moji práci a dodávaná řešení.</p>
  <h3>DevOps</h3>
  <div class="icon-right-knowledge"><img alt="Znalosty" src="assets/icons/work.png"/></div>
  <p>V rámci posledních realizovaných projektů, jsem pracoval i jako DevOps inženýr v prostředí Visual Studio Team
    Services (VSTS). Zde jsem zajišťoval automatizaci testovaní včetně code coverage pro C#, Angular 2+, Python kódy.
    Překlad a následné automatické nasazení do testovacích i provozních prostředí. Vše bylo řízeno úspěšností
    jednotlivých kroků a případnou validací ze strany QA zodpovědných lidí. Snahou celé implementace byla maximální
    unifikace postupů a zrychlení nasazovaní nových verzí produktu v rozsáhlém produkčním systému. Současně s tímto jsem
    i připravoval potřebné AZURE služby a jejich šablony pro rychlé nasazení v produkci.</p>
  <h1>Testovaní</h1>
  <div class="icon-left-knowledge"><img alt="Znalosti" src="assets/icons/work.png"/></div>
  <p>Od roku 2018 se ve společnosti vyvíjecí software pro nahrávání IPT, starám o performance testovaní tohoto systému.
    Nejedná se o běžné měření webových aplikací, ale kompletní a komplexní měření produktu včetně generování IPT zátěže.
    Moje práce začíná u definice metodiky použité pro měření a končí u vyhodnocení testů. Díky této zkušenosti mohu
    nabídnou konzultace i v oblasti performance měření.</p>
</div>
