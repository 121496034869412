<div class="content_item">
  <p>Co by fanda do výpočetní techniky a nadšence pro oblast programování, jsem vytvořil několik programových projektů.
    Zde je několik z nich.</p>
  <h1>Google Contact Synchronizer for Office 2010 Add-In</h1>
  <p>V době působení ve společnosti Hewlett-Packard jsem používal pro email komunikaci korporátní systém postavený na
    řešené společnosti Microsoft. Jednalo se o globálně spravovaný Exchange a na mém PC MS Outlook 2010. Ve stejné době
    jsem, ale používal telefon s operačním systémem Android. Tato kombinace mne vedla k hledání řešení pro synchronizaci
    především kontaktů mezi mým účtem Google a aplikací Outlook.</p>
  <p>Trh sice v době tvorby tohoto nástroje poskytoval několik nástrojů pro synchronizaci kontaktů mezi Google a
    Outlook, ale jejich funkčnost neodpovídala požadované ceně či byla velice chybová. Díky tomu jsem v roce 2011
    započal s vlastní projektem synchronizačního nástroje pro Outlook 2010 a systém Google.</p>
  <p>Výsledky své práce jsem aktivně používal a rozvíjel do druhé poloviny roku 2012. Celý systém je volně přístupný jak
    Open Source aktivita na stránkách CodePlex.</p>
  <p>Aplikace k nalezní na adrese <a href="https://github.com/pokornyIt/ContactSync">GitHub Contact Sync</a></p>
  <h1>Leonardo Agent Dashboard</h1>
  <p>Leonardo je speciální aplikací napsanou pro společnost CSA Services, s.r.o. tato aplikace byla součástí mnou
    podporovaného řešení kontaktního centra u této společnosti.</p>
  <p>Z důvodu předání aplikace do držení zadavatele, jsem se s nimi dohodl, že bude uvedena jako Open Source počin.</p>
  <p>Aplikace k nalezení na adrese <a href="https://github.com/pokornyIt/leonardoagent">GitHub Leonardo Agent</a></p>
  <h1>CIM Word Setup</h1>
  <p>Komerčně psaná webová aplikace pro rozšíření funkcionality systému CISCO Interactive Email Manager. Aplikace
    umožňuje oprávněným osobám měnit slovníky, podle kterých je následně řízeno řazení zpracovávaných email zpráv. </p>
  <p>Prvotně byla aplikace napsána pro řešení kontaktního centra CSA Services, s.r.o., kde byla konfigurována pro
    správné rozeznávání jazyka přicházející email zprávy. Po prvotním ladění slovy byla úspěšnost určení jazyka více jak
    90%.</p>
  <p>Dalším nasazením této aplikace je kontaktní centrum jedné z finančních nebankovních institucí v ČR. Zde se užívá k
    rozdělení požadavků dle řady klíčových slov. Pro tohoto zákazníka byla aplikace rozšířena o funkcionalitu analýzy
    odchozích zpráva změn oproti předdefinovaným předlohám. Tato část pomáhá supervizorům při kontrole práce agentů, kdy
    jsou ke kontrole vybrány pouze zprávy, které mění fixní části předlohy.</p>
  <p>Tato aplikace není volně dostupná. Aplikace byla vytvořena společně se společností <a href="http://www.angeco.cz/">Angeco,
    s.r.o.</a></p>
  <h1>IPT Reports</h1>
  <p>Aplikace vytvořená na základě požadavků společnosti Atlas Copco. Jedná se reporting vybraných informací ze systémů
    CISCO UC (CUCM, UCCX, ExpressWay). IPT Reports pomáhají společnosti Atlas Copco při vnitřním přeúčtovávání nákladů
    provozovatel UC na jednotlivé divize, země, regiony.</p>
  <p>Reporty řeší problematiku v komplexních implementacích s větším počtem instalovaných systémů (CUCM clusterů atp.).
    Umožňují administrátorům rychle vyhledat informace napříč celým řešením. Příkladem je určení home clusteru uživatele
    či jako licenci má přidělenu systémem.</p>
  <p>Systém je doplněn omezením přístupu k informací dle uživateli přidělené role. Ověřování uživatelů je primárně
    vedeno skrze home cluster přihlašovaného uživatele. Unikátností celého řešení je přenositelný mezi platformami
    (Windows/Linux) a webovými servery (IIS/Apache) bez změn kódu. K provozu není potřeba žádná další databáze.</p>
  <p>Tato aplikace není volně dostupná. Aplikace byla vytvořena ve spolupráci zadavatele a společnosti <a
    href="http://www.misesro.com">MISE, s.r.o.</a></p>
  <h1>JWT Proxy</h1>
  <p>Aplikace vytvořená pro potřeby systému eGain WebChat k získání informací o autorizovaném uživateli z JWT
    autorizační cookies. Systém podporuje autologin v chat template, kdy informace o uživateli není přístupná přímo z
    UI, ale pouze z cookies.</p>
  <p>Aplikace podporuje i dodatečné získání informace o uživateli v web chat komunikaci pomocí integračních služeb
    systému eGain.</p>
  <p>Aplikace byla vyvinuta pro specifickou implementaci u zákazníka z bankovního sektoru implementující eGain WebChat
    na svém bankovnictví. Aplikace není volně přístupná.</p>
  <h1>UC Reporting</h1>
  <p>UC Reporting je aplikace vyvíjena dle požadavků společnosti Epiroc. Aplikace má svůj základ v aplikaci IPT Reports
    napsané pro společnost Atlas Copco, kterou rozšiřuje o reporting služeb WebEx a grafy využívání služeb.</p>
  <p>Aplikace pomáhá internímu IT společnosti s řešením uživatelských problémů v oblasti IP telefonie. Příkladem je
    možnost IT operátorů rychle a efektivně dohledat potřebné informace spojené s uživatelem IP telefonie. Například
    jaké device má přiřazeny, zda je členem line group či pickup group, jak často se účastní WebEx konferencí, či kdy se
    naposledy přihlásil do systému. Dostupné informace obsahují i detaily o verzi SW a aktuální statusu uživatelského
    device v systému.</p>
  <p>Propojení s firemním HR systémem je skrze služby AZURE Service Bus. Pro napojení dalších systémů aplikace nabízí
    plnohodnotné REST API a také databázový přístup. Tato rozhraní budou využita v automatizaci konfigurace IPT systému
    pro nové zaměstnance bez zásahu IT.</p>
  <p>Tato aplikace není volně dostupná.</p>
</div>
