import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutComponent} from './about/about.component';
import {KnowledgeComponent} from './knowledge/knowledge.component';
import {ProjectsComponent} from './projects/projects.component';
import {ServicesComponent} from './services/services.component';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  {path: 'about', component: AboutComponent},
  {path: 'knowledge', component: KnowledgeComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'home', component: HomeComponent},
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
