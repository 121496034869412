<h1>První zkušenosti</h1>
<div class="icon-right-knowledge">
  <img alt="O mne" src="assets/icons/testimonial.png"/>
</div>
<p>
  Od věku mladičkého teenagera, jsem věděl, že budu dělat v oblasti výpočetní techniky. Již na základní škole jsem se
  věnoval programování, v té době to byl programovatelný kalkulátor Texas Instrument TI-57 (<a
  href="http://cs.wikipedia.org/wiki/TI-57">pro představu</a>). V průběhu konce střední školy jsem se stal šťastným
  majitelem osmi bitového počítače <a href="http://cs.wikipedia.org/wiki/Sinclair_ZX_Spectrum">Sinclair ZX Spectrum+</a>.
  Ten mi otevřel cestu k programování, nejdříve v integrovaném jazyku BASIC a posléze v Assembleru procesoru
  <a href="http://cs.wikipedia.org/wiki/Z80">Zilog Z80</a>.
</p>
<p>S přechodem na VŠ jsem se začal učit tehdy populární a dostupný Borland Pascal a následně i C verze 2.0. V této době
  jsem přešel na počítače PC. Programování rutin v jazyce Fortran a Cobol probíhalo při brigádách na různých výpočetních
  střediscích se sálovými počítači typu EC-1020. Jedním z mých prvních počinů v oblasti budování a administrace systémů,
  bylo vybudování a správa počítačových učeben na VŠZ v Praze, vše na síťovém systému Novell 2.5 a posléze verze
  3.x.</p>
<p>V první zaměstnání jsem byl odborníkem na kabeláž počítačových sítí a správcem interního systému společnosti
  postaveného na Windows 3.0. S rozvojem mateřské společnosti jsem se stal specialistou na kabelový systém AT&T Systimax
  a Belden. Své znalosti jsem zužitkoval v projektech budování IS ve společnosti Škoda Plzeň a Chemopetrol Litvínov či
  Libereckém Liaz.</p>
<p>Postupem času jsem k vedení projektů na kabelážní systémy, administraci interního systému mateřské společnosti
  přibral i první webovou prezentaci společnosti s aktivními prvky psanými v ASP.</p>
<h1>První kroky v IPT až po komplexní kontaktní centrum</h1>
<div class="icon-left-knowledge">
  <img alt="O mne" src="assets/icons/testimonial.png"/>
</div>
<p>S následným přestupem do společnosti Hewlett-Packard, jsem se zaměřil od pasivních částí sítí na jejich aktivní části
  a především zařízení s pro Voice over IP. První síť propojující tradiční telefonní ústředny pomocí VoIP jsem budoval
  již v roce 1999 na prvcích společnosti CISCO. Tehdy se jednalo o propojení poboček obchodního řetězce. S každým rokem
  přibývalo zkušeností a projektů v oblasti implementace VoIP až v roce 2002 přišla první implementace IP kontaktního
  centra. Implementace systému CISCO ICM verze 4.6 ve společnosti UAMK ukázala kam se budu dále ubírat.</p>
<p>Následovaly projekty kontaktních center a jejich integrace s dalšími systémy zadavatele. S ohledem na v té době
  strategické partnerství společnosti Hewlett-Packard a CISCO Systems, byly všechny implementace IPT a kontaktních
  center na systémech společnosti CISCO.</p>
<p>K zajímavým projektům se řadil automatický hlasový bankovní systém společnosti HVB, kde byla integrace s bankovními
  systémy, agentským desktopem, či první dvanácti jazyčné kontaktní centrum plně na IP technologii s integrací do
  zákaznické znalostní databáze a tvorbou prvních specializovaných reportů. Následovala pilotní implementace a následný
  několikaletý deployment IPT ve společnosti VZP či vybudování multimediálního kontaktního centra se zákaznickým
  reportingem a agentskou aplikací pro CSA Services.</p>
<p>U všech projektů IPT a kontaktních center jsem byl jak navrhovatelem řešení, implementátorem, tak členem supportního
  teamu. Díky účasti ve všech pozicích jsem získal mnoho zkušeností, které jsou nepřenositelné a pomáhají mi při
  navrhování, implementaci i podpoře dalších systémů.</p>
<p>Po rozchodu společností CISCO Systém a Hewlett-Packard jsem se postupně začal věnovat technologiím společnosti AVAYA
  a rychle získávat i potřebná školené a certifikace pro dodávky kontaktních center a IPT tohoto výrobce. Současně s tím
  jsem do svého portfolia specialisty na IPT a kontaktní centra přibral i Microsoft OCS 2007/2007 R2 a posléze, Lync a
  Skype for Business. Další oblastí, ve které jsem působil, jsou produkty společnosti ACME Packet.</p>
<p>Po ukončení práce pro Hewlett-Packard, jsem začal své znalosti rozšiřovat dále. Aktuálně krom výše uvedených produktů
  společnosti CISCO Systems, AVAYA, Microsoft, mám projekty otestované zkušenosti s produkty Genesys a to jak v oblasti
  správy tak i podpory.</p>
<p>Dalším významným výrobcem, jehož produkty komplexně ovládám je společnost eGain. Produkty eGain podporuji a znám v
  jejich on-premise portfoliu. Mé znalosti jdou od návrhu řešení, přes jeho implementaci, podporu až po vývoj
  speciálních doplňků a podpůrných nástrojů, které vhodně doplňují chybějící části.</p>
<h1>Konzultace</h1>
<div class="icon-right-knowledge">
  <img alt="O mne" src="assets/icons/testimonial.png"/>
</div>
<p>V současné době mé portfolio zahrnuje systémy společnosti CISCO, především Unified Communications Manager a
  Communications Manager IM & Presence Service, Cisco Expressway, IP IVR/Unified Contact Center Express, CVP, Unified
  Contact Center Enterprise a CIM/WIM.</p>
<p>Na straně produktů společnosti AVAYA jde především o Communication Manager 5.2 a výše včetně systému Elite, AVAYA
  AURA Contact Center, Session Manager, AES od verze 5.2 a&nbsp; videořešení Radvision.</p>
<p>V oblasti produktů Microsoft se jedná o systém Lync a Skype for Business.</p>
<p>Pro produkty společnosti Genesys jde o řešení GVP, SIP Server, T-Servers, CC Analyzer, CCPulse+, UR, GMS, GQM.</p>
<p>Konzultace u produktů eGain zahrnují jejich on-premise řešení od verze 9 až pro aktuální eGain Solve 17 a to včetně
  integrace s produkty CISCO Systems.</p>

<p>Díky znalostem sesbíraným za dobu působení ve společnosti Hewlett-Packard mohu dnes nabídnout velmi široké portfolio
  konzultačních služeb v oblastech IP Telefonie či konzultace v oblasti správy menších systémů na bázi Windows.</p>
