<div class="slide-images">
  <div>
    <div *ngFor="let img of images;index as i;" [@openClose]="actualImage===i" [hidden]="actualImage!==i" class="slide">
      <img alt="{{img.caption}}" src="{{img.uri}}">
      <div class="caption">{{img.caption}}</div>
    </div>
  </div>
</div>

<!--<div id="banner_image">-->
<!--<ul #slideshow  class="slideshow">-->
<!--  <li >-->
<!--    <img @start-page alt="Konzultace UC & CC" src="assets/slide1.jpg" class="img">-->
<!--  </li>-->
<!--  <li>-->
<!--    <img @start-page alt="Administrace" src="assets/slide2.jpg" class="img">-->
<!--  </li>-->
<!--  <li>-->
<!--    <img @start-page alt="Programování" src="assets/slide3.jpg" class="img">-->
<!--  </li>-->
<!--  <li #caption class="caption" id="slideshow-caption">-->
<!--    <div class="slideshow-caption-container">-->
<!--      <p>Programování</p>-->
<!--    </div>-->
<!--  </li>-->
<!--</ul>-->
<!--</div>-->
