import {Component, OnInit} from '@angular/core';
import {ImageModel} from '../image-model';
import {animate, query, state, style, transition, trigger} from '@angular/animations';

// const fadeOut = [
//   style({display: 'none'}),
//
// ];
// const startPage = [
//   state(
//     ':enter', style({opacity: 0.0})),
//   transition('* => *',
//     animate('1s',
//       style({opacity: 1.0})
//     )
//   )
// ];

const ShakeAnimation = [
  style({transform: 'rotate(0)'}),
  animate('0.5s', style({transform: 'rotate(2deg)'})),
  animate('0.5s', style({transform: 'rotate(-2deg)'})),
  animate('0.5s', style({transform: 'rotate(2deg)'})),
  animate('0.5s', style({transform: 'rotate(0)'})),
];

export const QueryShake = [
  trigger('queryShake', [
    transition('* => default', [query('.img', ShakeAnimation)]),
  ]),
];

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({height: '*'})),
      state('false', style({height: '0px'})),
      transition('false <=> true', animate(800))
    ]),
    // trigger('start-page', [
    //   transition('* => default', [
    //     style({opacity: 1}), animate('1s', style({opacity: 0}))
    //   ])
    // ]),
  ]
})
export class SlideShowComponent implements OnInit {
  // @ViewChild('caption', {static: false}) caption;
  // @ViewChild('slideshow', {static: false}) slideshow;

  actualImage: number;

  images: ImageModel[] = [
    {
      uri: 'assets/slide1.jpg',
      caption: 'Konzultace UC & CC',
    },
    {
      uri: 'assets/slide2.jpg',
      caption: 'Administrace'
    },
    {
      uri: 'assets/slide3.jpg',
      caption: 'Programování'
    }

  ];

  constructor() {
    this.actualImage = 0;
  }

  ngOnInit(): void {
    // this.caption.

    setInterval(() => {
      this.actualImage = this.actualImage + 1 < this.images.length ? this.actualImage + 1 : 0;
    }, 8000);
  }
}
